<script>
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'ForgotPassword',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: ['reference'],
  data() {
    return {
      email: '',
      result: undefined,
      submitting: false,
    };
  },
  methods: {
    async checkLoggedIn() {
      if (await this.$auth.currentAuthenticatedUser().catch(() => undefined)) {
        await this.$router.push({ path: '/dashboard' });
      }
    },
    async submit() {
      this.submitting = true;
      const target = `${process.env.VUE_APP_API_ENDPOINT}/public/users/password-reset`;
      const data = { email: this.email, subscriber: String(this.$i18n.t('site.subscriberId')), legacy_links: true };
      this.result = await axios.post(target, data).catch(({ response = undefined }) => response);
      this.submitting = false;
    },
  },
  async mounted() {
    await this.checkLoggedIn();
  },
};
</script>

<template>
  <div class="container">
    <div class="row p-2">
      <div class="col-12 border-bottom text-center">
        <h2 class="title">Forgot your password?</h2>
      </div>
    </div>
    <div v-if="result && result.status === 204" class="row p-2">
      <div class="col-12 text-success">A new link has been sent. Please check your email for further instructions.</div>
    </div>
    <ValidationObserver v-else ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="submit()">
      <div class="row p-2">
        <div class="col-12">
          <p>To request a new password reset link enter the primary email for your case below and click submit.</p>
        </div>
      </div>
      <ValidationProvider ref="email" v-slot="{ errors }" name="email" rules="required|email">
        <div class="row p-2">
          <div class="col-sm-3 col-xs-12">
            <label for="email">Email:</label>
          </div>
          <div class="col-sm-9 col-xs-12">
            <b-form-input id="email" v-model="email" name="email" type="email" />
          </div>
        </div>
        <div v-for="(error, index) in errors" v-bind:key="index" class="row p-2">
          <div class="col-12 text-danger">
            {{ error }}
          </div>
        </div>
      </ValidationProvider>
      <div v-if="result && result.status && result.status !== 204" class="row p-2" v-bind="result">
        <div v-if="Array.isArray(result.data.message)" class="col-12 text-danger">
          <div v-for="(message, i) in result.data.message" v-bind:key="i">{{ message }}</div>
        </div>
        <div v-else class="col-12 text-danger">
          {{ result.data.message }}
        </div>
      </div>
      <div class="row p-2">
        <div class="col-12">
          <button :disabled="invalid || submitting" class="btn bg-primary col-12" type="button" @click="submit">
            Submit <i v-if="submitting" class="icon-spinner11 mr-2 spinner"></i>
          </button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<style lang="scss" scoped>
h2.title {
  @media only screen and (max-width: 561px) {
    font-size: 22px;
  }
}
</style>
